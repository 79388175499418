import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addWorkType(payload) {
    return Api().post(`/v2/workType?organization_id=${organization_id()}`, payload);
  },
  editWorkType(payload, id) {
    return Api().put(`/v2/workType/${id}?organization_id=${organization_id()}`, payload);
  },

  getAllWorkType(filters, sort) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/workType?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/workType?organization_id=${organization_id()}`);
    } else
      return Api().get(`/v2/workType?organization_id=${organization_id()}`);
  },

  getDesignation(payload, id) {
    return Api().get(`/v2/workType/${id}?organization_id=${organization_id()}`, payload);
  },
};
